<template>
  <section id="welcome" style="padding: 0">
    <v-container fluid>
      <v-layout row wrap style="margin-top: 10%" justify-center align-center>
        <v-flex
          xs12
          sm12
          :style="mdUp ? 'text-align: left;' : 'text-align: center;'"
        >
          <span
            :style="
              mdUp
                ? 'color:#772336;letter-spacing: 3.2px;margin-left:8%;'
                : 'color:#772336;letter-spacing: 3.2px;'
            "
          >
            02
            <div class="line"></div>
            STC</span
          >
          <br /><br /><br />
          <h1
            :style="
              mdUp
                ? 'font-size:60px;margin-left:8%;margin-right:2%;'
                : 'font-size:30px;'
            "
          >
            Reciclaje
          </h1>
          <br /><br />
          <h2
            :style="
              mdUp
                ? 'font-size:20px;margin-left:8%;margin-right:45%'
                : 'font-size:20px;margin-left:2%;margin-right:2%'
            "
          >
            Una parte muy importante para nuestra empresa es la valoración de
            los residuos susceptibles a reciclaje, en STC buscamos una solución
            para nuestros clientes y así trabajar de la mano con proyectos en
            armonía con el medio ambiente.
          </h2>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- Servicios Title> -->
    <v-container fluid>
      <v-layout row wrap style="margin-top: 0" justify-center align-center>
        <v-flex
          xs12
          sm6
          :style="mdUp ? 'text-align: left;' : 'text-align: center;'"
        >
          <span
            :style="
              mdUp
                ? 'color:#772336;letter-spacing: 3.2px;margin-left:9%;'
                : 'color:#772336;letter-spacing: 3.2px;'
            "
          >
            02
            <div class="line"></div>
            STC</span
          >
          <br />
          <br /><br />
          <h1
            :style="
              mdUp
                ? 'font-size:40px;margin-left:6%;margin-right:4%;'
                : 'font-size:30px;margin-left:2%;margin-right:2%'
            "
          >
            <v-img contain width="300" src="/static/logo_verde.png"></v-img>

            Nuestro compromiso social con el medio ambiente...
          </h1>
          <br /><br />
          <h2
            :style="
              mdUp
                ? 'font-size:20px;margin-left:6%;margin-right:4%'
                : 'font-size:20px;margin-left:2%;margin-right:2%'
            "
          >
            Nos lleva a ofrecer nuestro servicio en cuanto al manejo integral de
            residuos susceptibles al reciclado, como: aceites gastados,
            escorias, vidrio, cartón, plástico, entre otros dejando como
            última opción la disposición final.
          </h2>
        </v-flex>
        <v-flex xs12 sm6>
          <v-img src="/static/DSC_0025.png" height="1000"></v-img>
        </v-flex>
        <v-flex xs12 sm6 v-if="mdUp">
          <v-img contain src="/static/DSC_0050.png"></v-img>
        </v-flex>
        <v-flex
          xs12
          sm6
          :style="mdUp ? 'text-align: left;' : 'text-align: center;'"
        >
          <h1
            :style="
              mdUp
                ? 'font-size:40px;margin-right:2%;margin-left:8%;'
                : 'font-size:30px;'
            "
          >
            Nuestro personal altamente capacitado
          </h1>
          <br /><br />
          <h2
            :style="
              mdUp
                ? 'font-size:20px;margin-right:2%;margin-left:8%;'
                : 'font-size:20px;margin-left:2%;margin-right:2%'
            "
          >
            Garantiza la mano de obra calificada para estas operaciones.<br />
            Contamos con cursos y certificaciones que avalan nuestro compromiso
            con el cliente:
            <br />
            <br />
            <span style="color: #772336">•</span> Residuos sólidos urbanos
            <br />
            <span style="color: #772336">•</span> Manejo de residuos peligrosos
            <br />
            <span style="color: #772336">•</span> Prevención y contaminación de
            suelo <br />
            <span style="color: #772336">•</span> Legislación ambiental <br />
            <span style="color: #772336">•</span> Protección civil: atención
            contra incendios manejo de extintores y primeros auxilios, brigadas.
            <br />
            <span style="color: #772336">•</span> Manejo de residuos de manejo
            especial <br />
            <span style="color: #772336">•</span> Manejo de sustancias químicas
            y equipo de protección personal. <br />
            <span style="color: #772336">•</span> Rig pass <br />
            <span style="color: #772336">•</span> 5’s
          </h2>
        </v-flex>
        <v-flex xs12 sm6 v-if="!mdUp">
          <v-img contain src="/static/DSC_0050.png"></v-img>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid style="padding: 0">
      <v-card
        flat
        tile
        class="white--text align-end imageweb"
        style="height: 450px; position: relative"
        :img="bannerimg"
      >
        <div class="bannerColor">
          <v-layout column align-baseline justify-center>
            <br />
            <v-img
              src="/static/logo_blanco.png"
              contain
              max-width="350"
              class="mobile-title"
            >
            </v-img>
            <p data-aos="fade-right" class="mobile-title" style="z-index: 1000">
              Somos la mejor opción para para el manejo integral de sus residuos
            </p>
            <p
              data-aos="fade-left"
              class="mobile-title2"
              :style="
                this.$vuetify.breakpoint.mdAndUp
                  ? 'z-index: 1000;max-width: 35%; letter-spacing: 0px'
                  : 'z-index: 1000;max-width: 60%; letter-spacing: 0px'
              "
            >
              Desde su generación, transporte y disposición final, con un
              servicio de calidad en armonía con el medio ambiente.
            </p>
          </v-layout>
        </div>
      </v-card>
    </v-container>

    <!-- Contact Form  change background-image-->
    <v-container fluid style="padding: 0">
      <v-layout
        row
        wrap
        style="
          margin-top: 0%;
          background-image: url('/static/FondoContact3.png');
          background-size: cover;
        "
        justify-center
        align-center
      >
        <v-flex
          xs12
          sm6
          class="contact"
          :style="mdUp ? 'text-align: left;' : 'text-align: center'"
        >
          <v-card
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'contact-mobile'"
            style="background: transparent"
            elevation="0"
          >
            <v-container>
              <v-layout row justify-center>
                <div
                  :class="
                    $vuetify.breakpoint.smAndUp
                      ? 'contact_form_wrapper'
                      : 'contact_form_wrapper_mobile'
                  "
                >
                  <v-layout row justify-center>
                    <h1
                      :style="
                        mdUp
                          ? 'color: white; font-size: 70px'
                          : 'color: white; font-size: 50px'
                      "
                    >
                      Contáctanos
                    </h1>
                  </v-layout>
                  <v-layout row justify-center>
                    <v-text-field
                      rounded
                      height="50"
                      background-color="white"
                      v-model="name"
                      :placeholder="
                        language == 0
                          ? 'Nombre y apellidos'
                          : 'Nombre y apellidos'
                      "
                    ></v-text-field>
                  </v-layout>
                  <v-layout row justify-center>
                    <v-select
                      v-model="gender"
                      rounded
                      height="50"
                      background-color="white"
                      item-value="value"
                      item-text="text"
                      :items="sex_options"
                      :placeholder="language == 0 ? 'Género' : 'Género'"
                    ></v-select>
                  </v-layout>

                  <v-layout row justify-center>
                    <v-text-field
                      rounded
                      height="50"
                      background-color="white"
                      v-model="email"
                      :placeholder="
                        language == 0
                          ? 'Correo electrónico'
                          : 'Correo electrónico'
                      "
                    ></v-text-field>
                  </v-layout>

                  <v-layout row justify-center>
                    <v-select
                      v-model="state"
                      rounded
                      height="50"
                      background-color="white"
                      :items="estadosMX"
                      item-value="nombre"
                      item-text="nombre"
                      :placeholder="
                        language == 0
                          ? 'Estado de la república mexicana donde reside'
                          : 'Estado de la república mexicana donde reside'
                      "
                    ></v-select>
                  </v-layout>

                  <v-layout row justify-center>
                    <v-text-field
                      rounded
                      height="50"
                      background-color="white"
                      v-model="phone"
                      :placeholder="
                        language == 0
                          ? 'Número de teléfono'
                          : 'Número de teléfono'
                      "
                    ></v-text-field>
                  </v-layout>

                  <v-layout row justify-center>
                    <v-textarea
                      rounded
                      height="150"
                      no-resize
                      style="resize: none; !important"
                      background-color="white"
                      v-model="comment"
                      :placeholder="
                        language == 0 ? 'Comentarios' : 'Comentarios'
                      "
                    ></v-textarea>
                  </v-layout>
                  <v-layout row justify-center>
                    <v-btn
                      large
                      outlined
                      text
                      dark
                      color="white"
                      @click="submitForm"
                    >
                      Enviar
                    </v-btn>
                  </v-layout>
                </div>
              </v-layout>
            </v-container>
            <v-container>
              <v-flex xs12>
                <v-layout row justify-center class="submit_form"> </v-layout>
              </v-flex>
            </v-container>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 v-if="mdUp">
          <v-img height="1000" src="/static/DSC_0020.png"></v-img>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import page_texts from "../../views/guests/guestTexts";

export default {
  data: () => ({
    name: "",
    gender: "",
    email: "",
    state: "",
    phone: "",
    comment: "",
    sex_options: [
      {
        text: "Hombre",
        value: "male",
      },
      {
        text: "Mujer",
        value: "female",
      },
      {
        text: "Otro",
        value: "other",
      },
    ],
    estadosMX: [
      { clave: "AGS", nombre: "Aguascalientes" },
      { clave: "BC", nombre: "Baja California" },
      { clave: "BCS", nombre: "Baja California Sur" },
      { clave: "CHI", nombre: "Chihuahua" },
      { clave: "CHS", nombre: "Chiapas" },
      { clave: "CMP", nombre: "Campeche" },
      { clave: "CMX", nombre: "Ciudad de México" },
      { clave: "COA", nombre: "Coahuila" },
      { clave: "COL", nombre: "Colima" },
      { clave: "DGO", nombre: "Durango" },
      { clave: "GRO", nombre: "Guerrero" },
      { clave: "GTO", nombre: "Guanajuato" },
      { clave: "HGO", nombre: "Hidalgo" },
      { clave: "JAL", nombre: "Jalisco" },
      { clave: "MCH", nombre: "Michoacán" },
      { clave: "MEX", nombre: "Estado de México" },
      { clave: "MOR", nombre: "Morelos" },
      { clave: "NAY", nombre: "Nayarit" },
      { clave: "NL", nombre: "Nuevo León" },
      { clave: "OAX", nombre: "Oaxaca" },
      { clave: "PUE", nombre: "Puebla" },
      { clave: "QR", nombre: "Quintana Roo" },
      { clave: "QRO", nombre: "Querétaro" },
      { clave: "SIN", nombre: "Sinaloa" },
      { clave: "SLP", nombre: "San Luis Potosí" },
      { clave: "SON", nombre: "Sonora" },
      { clave: "TAB", nombre: "Tabasco" },
      { clave: "TLX", nombre: "Tlaxcala" },
      { clave: "TMS", nombre: "Tamaulipas" },
      { clave: "VER", nombre: "Veracruz" },
      { clave: "YUC", nombre: "Yucatán" },
      { clave: "ZAC", nombre: "Zacatecas" },
    ],
    items: [
      {
        title:
          "Pipas con equipo especial de succión y vacío con capacidad de 30m3",
        img: "/static/DSC_0030.png",
        pos: 0,
      },
      {
        title: "Cargador Frontal",
        img: "/static/DSC_0024.png",
        pos: 1,
      },
      {
        title: "Volteo con capacidad de carga de 25 a 30m3",
        img: "/static/DSC_0021.png",
        pos: 2,
      },
      {
        title: "Cajas Secas",
        img: "/static/DSC_0016.png",
        pos: 3,
      },
      {
        title: "Presas para almacenamiento de Lodo con capacidad para 30m3.",
        img: "/static/PRESA.png",
        pos: 4,
      },
      {
        title: "Roll-off",
        img: "/static/DSC_0020.png",
        pos: 5,
      },
      {
        title: "Cama baja",
        img: "/static/DSC_0019.png",
        pos: 6,
      },
    ],
    valuesimg: ["/static/f6.jpg", "/static/f2.jpg", "/static/f1.jpg"],
    bannerimg: "/static/BF2.png",
    informacion: ["Ferretería y Herrajes", "Electrodomésticos ", "Eléctrica"],
    information: ["Hardware store", "Home Appliances", "Electrical"],
    section2: "",
    section3: "",
    headerEsp: "",
    headerEng: "",
    currentTab: 0,
    imagenes: [
      "/static/facilities2.png",
      "/static/facilities2.png",
      "/static/facilities2.png",
    ],

    servicesManu: [
      { name: "METAL FORMING", img: "/static/genvaimg.png" },
      { name: "POWDER COATING", img: "/static/genvaimg.png" },
      { name: "ELECTROPLATING", img: "/static/genvaimg.png" },
      { name: "ZINC DIE CASTING", img: "/static/genvaimg.png" },
      { name: "ASSEMBLY & TESTING", img: "/static/genvaimg.png" },
      { name: "SOURCED AND LANDED ACTIVITIES", img: "/static/genvaimg.png" },
    ],
    servicesComer: [
      { name: "REPRESENTACIÓN COMERCIAL", img: "/static/genvaimg.png" },
      { name: "BRAND AWARENESS / MARKETING", img: "/static/genvaimg.png" },
      { name: "3PL", img: "/static/genvaimg.png" },
      { name: "BRANDS & PRODUCTS", img: "/static/genvaimg.png" },
    ],
  }),
  computed: {
    mdUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    language() {
      // iniciatlize global language for this section ( 0 = spanish, 1 = english)
      return this.$store.state.language;
    },
    texts() {
      // return the texts JSON based on the language chosen
      // if more languages are added, convert into a switch with param (this.language)
      return this.language == 0 ? page_texts.spanish : page_texts.english;
    },
  },
  watch: {
    currentTab(val) {},
  },
  methods: {
    submitForm() {
      let that = this;
      var axios = require("axios");
      var qs = require("qs");
      var data = qs.stringify({
        name: this.name,
        gender: this.gender,
        email: this.email,
        state: this.state,
        phone: this.phone,
        comment: this.comment,
        type: "recyclable",
      });
      var config = {
        method: "post",
        url: "https://api.grupostc.com.mx/api/v1/serviceContacts",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          that.name = "";
          that.gender = "";
          that.email = "";
          that.state = "";
          that.phone = "";
          that.comment = "";

          that.$store.commit("toggle_alert", {
            color: "green",
            text: response.data.message,
          });
        })
        .catch(function (error) {
          console.log(error);
          that.$store.commit("toggle_alert", {
            color: "red",
            text: error.response.data.message,
          });
        });
    },
    setImageBanner() {
      var image = new Image();
      image.onload = function () {
        console.info("Image loaded !");
        //do something...
      };
      image.onerror = function () {
        console.error("Cannot load image");
        //do something else...
      };
      image.src = "/images/blah/foo.jpg";
    },
    getText() {
      var axios = require("axios");

      let that = this;

      var config = {
        method: "get",
        url:
          "http://ec2-107-23-108-138.compute-1.amazonaws.com/api/information/",
        headers: {},
      };

      axios(config)
        .then(function (response) {
          that.section2 =
            response.data.information.images.pageTwo.sectionTwo.image[0];
          that.section3 =
            response.data.information.images.pageTwo.sectionThree.image[0];

          that.headerEsp =
            response.data.information.spanish.pageTwo.header.title;

          that.headerEng =
            response.data.information.english.pageTwo.header.title;

          page_texts.spanish.who.qualityTitle =
            response.data.information.spanish.pageTwo.sectionOne.title;

          page_texts.english.who.qualityTitle =
            response.data.information.english.pageTwo.sectionOne.title;

          page_texts.spanish.who.qualityDesc =
            response.data.information.spanish.pageTwo.sectionOne.description;

          page_texts.english.who.qualityDesc =
            response.data.information.english.pageTwo.sectionOne.description;

          page_texts.spanish.who.misionTitle =
            response.data.information.spanish.pageTwo.sectionTwo.title;

          page_texts.english.who.misionTitle =
            response.data.information.english.pageTwo.sectionTwo.title;

          page_texts.spanish.who.misionDesc =
            response.data.information.spanish.pageTwo.sectionTwo.description;

          page_texts.english.who.misionDesc =
            response.data.information.english.pageTwo.sectionTwo.description;

          page_texts.spanish.who.visionTitle =
            response.data.information.spanish.pageTwo.sectionThree.title;

          page_texts.english.who.visionTitle =
            response.data.information.english.pageTwo.sectionThree.title;

          page_texts.spanish.who.visionDesc =
            response.data.information.spanish.pageTwo.sectionThree.description;

          page_texts.english.who.visionDesc =
            response.data.information.english.pageTwo.sectionThree.description;

          that.$forceUpdate();
        })
        .catch(function (error) {});
    },
  },
  mounted() {
    this.getText();
  },
};
</script>

<style scoped>

.textarea {
  resize: none !important;
}

.mobile-title {
  margin: 0 auto;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 4vh;
}

.mobile-title2 {
  text-align: center;
  max-width: 30%;
  margin: 0 auto;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  margin-top: 20px;
}

.bannerColor {
  background-color: #005137c5;
  height: 100%;
  width: 100%;
}

.contact {
  background: url("https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg")
    no-repeat center center;
}

.contact_form_wrapper {
  max-width: 70%;
  width: 10000px;
}
.contact_form_wrapper_mobile {
  max-width: 90%;
}

.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 1;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.para-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    to top right,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.33)
  );
}
.gradient-wrapper-who {
  position: relative;
}

div.line {
  margin-bottom: 0.5%;
  position: relative;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  width: 10%;
  border-bottom: 1px solid #772336;
}

.gradient-wrapper-who:after {
  content: "";
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(
    to bottom,
    rgba(41, 41, 41, 0.486) 0%,
    rgba(41, 41, 41, 0.486) 100%
  );
}
.header_desktop {
  text-align: center;
  font-size: 80px;
}

.header_mobile {
  font-size: 40px;
  text-align: center;
}
.doctor-info-div {
  background-color: #e4edf1;
  padding: 100px;
}
.doctor-info-div-mobile {
  background-color: #e4edf1;
  padding: 20px;
}
.gradient-wrapper {
  position: relative;
}
.align-center-class {
  text-align: center;
}
.gradient-wrapper:after {
  content: "";
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(
    to bottom,
    rgba(56, 65, 117, 0.7) 0%,
    rgba(56, 65, 117, 0.7) 100%
  );
}
.blue-div {
  position: relative;
  margin-top: 20px;
  font-size: 22px;
  height: 4px;
  width: 100px;
  background-color: #81ccf7;
  margin-bottom: 20px;
}
.medium-text {
  font-size: 22px;
  font-weight: bold;
}
.small-text {
  font-size: 12px;
}
.welcome-flex-sm {
  position: relative;
  padding: 0 5px !important;
}
.welcome-container-md {
  padding: 0 10vw;
  margin: 0;
  width: 100%;
}
.text-flex-sm {
  text-align: center !important;
}
.container_text_home_card {
  padding: 10vw 10vw !important;
}
.adjust_home_card {
  margin-left: 8%;
  margin-top: -14%;
}
.adjust_home_car_mobile {
}
.opening_quote {
  width: 150px;
}
.opening_quote_mobile {
  width: 50px;
  margin-left: 10px;
}
.v-tabs-slider {
  display: none !important;
}
.active-tab {
  background-color: #d8dcef !important;
}
.closing_quote {
  margin-top: 50px;
  width: 150px;
  margin-bottom: -70px;
  margin-left: 10px;
}
.closing_quote_mobile {
  position: relative;
  bottom: 30px;
  width: 50px;
  margin-right: 10px;
}
.home_card_text {
  width: 40vw;
  position: absolute;
  margin-top: 10%;
  color: black;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: lighter;
  font-style: normal;
  text-align: left;
  margin: auto;
  max-width: 80%;
}
.card_home_wrapper {
  margin-top: -123px;
  border-radius: 6px;
  height: 500px;
  max-height: 170px;
  min-width: 225px;
  max-width: 45%;
  padding: 10px;
}
.card_home_wrapper_mobile {
  border-radius: 6px;
  height: 200px;
  max-height: 200px;
  min-width: 90%;
  max-width: 90%;
  padding: 10px;
}
.home_card_text_mobile {
  color: black;
  font-family: "Raleway", sans-serif;
  font-size: 4vw;
  font-weight: lighter;
  font-style: italic;
  text-align: center;
  margin: auto;
  max-width: 80%;
}
.doctor-img {
  position: relative;
  right: 30%;
  max-width: 30vw;
  max-height: 45vw;
}
.doctor-img-mobile {
  position: relative;
  margin-left: 30%;
  max-width: 30vw;
  max-height: 50vw;
}

.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.75;
}

@media (max-width: 900px) and (min-width: 0px) {
  .mobile-title {
    margin: 0 auto;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 600;
    font-size: 3vh;
  }
}
</style>
